import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeCompanySkeleton } from "./TypeCompany";
import type { TypeProjectSkeleton } from "./TypeProject";
import type { TypeThemeSkeleton } from "./TypeTheme";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeDownloadFields = {
  title: EntryFieldTypes.Symbol;
  shortTitle?: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
  project?: EntryFieldTypes.EntryLink<TypeProjectSkeleton>;
  company?: EntryFieldTypes.EntryLink<TypeCompanySkeleton>;
  themeOrTopic?: EntryFieldTypes.EntryLink<TypeThemeSkeleton | TypeTopicSkeleton>;
  access?: EntryFieldTypes.Array<EntryFieldTypes.Symbol<"Journalist" | "Member" | "Public">>;
  url?: EntryFieldTypes.Symbol;
  videoThumbnail?: EntryFieldTypes.AssetLink;
  formHandlerUrl?: EntryFieldTypes.Symbol;
  languages?: EntryFieldTypes.Array<EntryFieldTypes.Symbol<"English" | "French" | "German" | "Japanese" | "Mandarin" | "Portuguese" | "Spanish">>;
  date: EntryFieldTypes.Date;
  contentType: EntryFieldTypes.Symbol<"Assessment" | "Company Response" | "Consultation" | "Datasheet" | "Engagement Letter" | "Factsheet" | "Investor Briefing" | "Investor Letter" | "Meeting Notes" | "Methodology" | "Podcast" | "Report" | "Sign-on Letter" | "Technical Annex" | "Terms of Reference" | "Video">;
  fileType: EntryFieldTypes.Symbol<"DOC" | "JPEG" | "PDF" | "URL" | "XML">;
  hidden: EntryFieldTypes.Boolean;
};

export type TypeDownloadSkeleton = EntrySkeletonType<TypeDownloadFields, "download">;
export type TypeDownload<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeDownloadSkeleton, Modifiers, Locales>;

export function isTypeDownload<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeDownload<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "download";
}
