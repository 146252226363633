<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  isTypeEvent,
  isTypeExternalPage,
  isTypePage,
  isTypeReport,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
  type TypeExternalPageSkeleton,
  type TypePageSkeleton,
  type TypeReportSkeleton,
} from "~/types/contentful";
import {
  hasImage,
  hasSubcopy,
  hasSubtitle,
  hasThemeOrTopic,
} from "~/lib/article/type-guards.js";
import { formatDate } from "~/lib/date-time";

const props = defineProps<{
  article: Entry<
    | TypeArticleSkeleton
    | TypeDownloadSkeleton
    | TypeExternalPageSkeleton
    | TypePageSkeleton
    | TypeReportSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
}>();

const date = computed(() =>
  formatDate(
    isTypeArticle(props.article)
      ? new Date(props.article.fields.publishDate)
      : isTypeEvent(props.article) && props.article.fields.startdate
        ? new Date(props.article.fields.startdate)
        : isTypeReport(props.article) && props.article.fields.date
          ? new Date(props.article.fields.date)
          : isTypeDownload(props.article)
            ? new Date(props.article.fields.date)
            : new Date(),
  ),
);

const type = computed(() => {
  if (isTypeArticle(props.article)) {
    return props.article.fields.articleType?.fields.title;
  } else if (isTypeEvent(props.article)) {
    return props.article.fields.eventType;
  } else if (isTypeReport(props.article)) {
    return props.article.sys.contentType.sys.id;
  } else if (isTypeDownload(props.article) && props.article.fields.contentType === "Video") {
    return props.article.metadata.tags.filter((tag) => tag.sys.id.includes("video"))[0]?.sys.id.replace("video", "").match(/[A-Z][a-z]+/g)?.join(" ");
  } else if (isTypeDownload(props.article)) {
    return props.article.fields.contentType;
  } else if (isTypeExternalPage(props.article)) {
    return props.article.fields.type;
  } else if (isTypePage(props.article)) {
    return "page";
  }

  return undefined;
});

const themeOrTopic = computed(() => {
  if (hasThemeOrTopic(props.article)) {
    return props.article.fields.themeOrTopic;
  }

  return undefined;
});

const subcopy = computed(() => {
  if (hasSubcopy(props.article)) {
    return props.article.fields.subcopy;
  } else if (hasSubtitle(props.article)) {
    return props.article.fields.subtitle;
  }

  return undefined;
});
</script>

<template>
  <div
    class="article-item flex w-full flex-row overflow-hidden rounded-sm border border-dark-blue/10 p-3 font-normal lg:flex-col lg:p-0"
  >
    <div
      v-if="
        (hasImage(article)
          && (article.fields.image?.fields.file
            || (isTypePage(article) && article.fields.thumbnail?.fields.file)))
          || (isTypeDownload(article) && article.fields.videoThumbnail?.fields.file)
      "
      class="article-item__image order-2 hidden w-full flex-initial md:block md:max-md:w-5/12 lg:order-1"
    >
      <UiDownloadLink
        v-if="isTypeDownload(article)"
        :download="article"
        class-list="relative inline-block"
      >
        <NuxtImg
          v-if="article.fields.videoThumbnail?.fields.file?.url"
          class="!h-auto !object-contain"
          :src="article.fields.videoThumbnail.fields.file.url"
          :alt="article.fields.videoThumbnail.fields.title"
          :width="460"
          :height="300"
          fit="contain"
          loading="lazy"
        />
        <div
          class="pointer-events-none absolute bottom-4 left-4 flex h-[44px] w-7 items-center justify-center rounded bg-light-royal-blue"
        >
          <div
            class="size-0 border-y-[10px] border-l-[15px] border-y-transparent border-l-white"
          />
        </div>
        <template #locked>
          <div class="relative inline-block">
            <NuxtImg
              v-if="article.fields.videoThumbnail?.fields.file?.url"
              class="!h-auto !object-contain"
              :src="article.fields.videoThumbnail.fields.file.url"
              :alt="article.fields.videoThumbnail.fields.title"
              :width="460"
              :height="300"
              fit="contain"
              loading="lazy"
            />
            <div class="absolute top-0 flex h-[300px] w-[460px] items-center justify-center bg-white/80">
              <NuxtIcon
                name="lock"
                class="h-6 w-5"
              />
            </div>
          </div>
        </template>
      </UiDownloadLink>
      <NuxtLink
        v-else
        :to="getUrl(article)"
      >
        <ReportCardImage
          v-if="type === 'report'"
          class="!mx-0 !mt-0"
          :image="article.fields.image"
        />
        <NuxtImg
          v-else-if="article.fields.image && article.fields.image.fields.file"
          class="!h-auto !object-contain"
          :src="article.fields.image.fields.file.url"
          :alt="article.fields.image.fields.title"
          :width="460"
          :height="300"
          fit="contain"
          loading="lazy"
        />
        <NuxtImg
          v-else-if="
            isTypePage(article) && article.fields.thumbnail?.fields.file
          "
          class="!h-auto !object-contain"
          :src="article.fields.thumbnail.fields.file.url"
          :alt="article.fields.thumbnail.fields.title"
          :width="460"
          :height="300"
          fit="contain"
          loading="lazy"
        />
      </NuxtLink>
    </div>
    <div
      class="order-1 flex w-full flex-col md:pe-4 md:max-md:w-7/12 lg:order-2 lg:flex-auto lg:p-4"
    >
      <div class="mb-2 md:mb-3">
        <TextBadge
          v-if="type"
          class="mb-[6px] me-2 h-[22px]"
        >
          {{ type }}
        </TextBadge>
        <ArticleTopic
          v-if="
            themeOrTopic
              && (
                (Array.isArray(themeOrTopic) && themeOrTopic?.length < 3)
                || isTypeDownload(article)
              )
          "
          :theme-or-topics="
            Array.isArray(themeOrTopic)
              ? themeOrTopic.filter(
                (
                  themeOrTopic,
                ): themeOrTopic is NonNullable<typeof themeOrTopic> =>
                  !!themeOrTopic,
              )
              : [themeOrTopic]
          "
        />
        <TextBadge
          v-else
          class="me-2 h-[22px]"
        >
          Multiple ESG Topics
        </TextBadge>
      </div>
      <h3 class="mb-1 font-semibold md:mb-4">
        <UiDownloadLink
          v-if="isTypeDownload(article)"
          :download="article"
        >
          {{ article.fields.title }}
        </UiDownloadLink>
        <NuxtLink
          v-else
          :to="getUrl(article)"
        >
          {{ article.fields.title }}
        </NuxtLink>
      </h3>
      <p class="mb-3 mt-1 hidden lg:block">
        {{ subcopy }}
      </p>
      <div class="mt-auto">
        <hr class="hr-divider" />
        <hr class="hr-divider" />
        <hr class="hr-divider" />
        <div class="mt-[10px] opacity-50 md:mt-3">
          {{ date }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.article-item {
  &__image:deep(.header-info__image) {
    img {
      @apply m-auto max-h-[230px];
    }
  }
}
</style>
